<template lang="pug">
  v-container#advanceModal(fluid='' tag='section')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(v-model='dialogPaid' width='350')
      template(v-slot:activator='{ on, attrs }')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Agregar Pago
        v-card-text
          v-form(ref='formAdvanceMovement' v-model='validAdvanceMovement' lazy-validation='' autocomplete="random-string")
            v-col.py-0(cols="12" md="12" style="margin-top: 18px;")
              v-menu(:disabled="advance.id" ref='menuPaid' v-model='menuPaid' :close-on-content-click='false' :return-value.sync='movement.paidDate' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(dense readonly v-model='movement.paidDate' label='Fecha de Creación' prepend-icon='mdi-calendar' v-on='on')
                v-date-picker(@change='$refs.menuPaid.save(movement.paidDate)' v-model='movement.paidDate' no-title='' scrollable='' style='margin:0px;' color="green lighten-1")
            v-col.py-0(cols='12' md='12' style="margin-top: 7px;")
              v-text-field(style="margin-top: 15px;" dense v-model='movement.paid' :rules="requeridos" label='Total Pago' type='number' maxlength="150")
            v-col(cols='12')
              v-textarea.purple-input(:rules="requeridos" label='Concepto' value='Descripión de Pago.' v-model='movement.concept')
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='dialogPaid = false')
            | Salir
          v-btn(color='primary' text='' @click='saveAdvanceMovement' :loading="loadingSaveMovement")
            | Agregar
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Registro de Anticipos
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-form(ref='formAdvance' v-model='validAdvance' lazy-validation='' autocomplete="random-string")
          v-container.py-0
            v-row
              v-col.py-0(cols="12" md="2" style="margin-top: 18px;")
                v-menu(:disabled="advance.id" ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='advance.createDate' transition='scale-transition' offset-y='' min-width='290px')
                  template(v-slot:activator='{ on }')
                    v-text-field(dense readonly v-model='advance.createDate' label='Fecha de Creación' prepend-icon='mdi-calendar' v-on='on')
                  v-date-picker(@change='$refs.menuFrom.save(advance.createDate)' v-model='advance.createDate' no-title='' scrollable='' style='margin:0px;' color="green lighten-1")
              v-col.py-0(cols="12" md="4")
                v-autocomplete(:rules='[rules.required]' v-model='advance.provider' :items='providersLst' return-object='return-object' item-text='completeData' label='Proveedor Interno')
              v-col.py-0(cols="12" md="4")
                v-autocomplete(:rules='[rules.required]' v-model='advance.provider' :items='providersLstExterno' return-object='return-object' item-text='completeData' label='Proveedor Externo')
              //v-col.py-0(cols="12" md="7")
                v-autocomplete(:disabled="advance.id" :rules='[rules.required]' v-model='advance.provider' :items='arrayProveedores' return-object='return-object' item-text='completeData' label='Proveedor')
              v-col.py-0(cols='12' md='2' style="margin-top: 7px;")
                v-text-field(@keyup="advance.balance = advance.total" :disabled="advance.id" style="margin-top: 15px;" dense v-model='advance.total' :rules="requeridos" label='Total Anticipo' type='number' maxlength="150")
              v-col.py-0(cols='12' md='12' style="margin-top: 7px;")
                v-text-field(@keyup="advance.concept = advance.concept.toString().toUpperCase()" :disabled="advance.id" style="margin-top: 15px;" dense v-model='advance.concept' :rules="requeridos" label='Concepto de Anticipo' type='text')
              v-row(style="margin-top: 0px;")
                v-col(cols="12" md="6")
                  v-row
                    v-col(cols="6" style="text-align: center;")
                      label(style="font-size: 25px; font-weight: bold;") PAGADO: ${{advance.paid !== '' ? advance.paid : '00.00'}}
                    v-col(cols="6" style="text-align: center;")
                      label(style="font-size: 25px; font-weight: bold;") SALDO: ${{advance.balance !== '' ? advance.balance : '00.00'}}
                v-col(cols="12" md="6")
                  v-row
                    v-col(cols="6" style="text-align: center;")
                      v-btn.mr-0(color='secondary' @click='printReceipt' style="width: 100%;" :loading="loadingSavePrint")
                        | IMPRIMIR RECIBO PRINCIPAL
                    v-col(cols="6" style="text-align: center;")
                      v-btn.mr-0(:disabled="advance.id" color='primary' @click='saveAdvance' style="width: 100%;" :loading="loadingSave")
                        | GUARDAR ANTICIPO
            hr(style="margin-top:40px;")
            v-row(style="margin-top: 10px; margin-bottom: 10px;")
              v-col(cols="6")
                label(style="font-size: 25px; font-weight: bold;") Lista de Pagos
              v-col(cols="6" style="text-align: center;")
                v-btn.mr-0(:disabled="!advance.id" color='orange' @click='addMovement' :loading="loadingSaveMovement")
                  | AGREGAR PAGO
            v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
            v-data-table.mx-2#tableGuides(:search="search" :headers="headers" v-show="!firstLoad" :items="movementsLoad" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" :sort-desc="true" calculate-widths :footer-props="{\'items-per-page-text':'Guías por página'\}")
              template(v-slot:item.action="{ item }")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='blue' v-on="on" @click="printReceiptSub(item)" :loading="loadingPrintAdvance")
                      v-icon(small) mdi-printer
                  span.tooltips Imprimir Recibo
              v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
              | Sin resultados
              v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                | Sin datos
              v-flex(xs12='', sm10='', offset-sm1='')
</template>
<script>
  import moment from 'moment'
  import recibojson from '../../PdfFiles/recibo.json'
  export default {
    props: {
      advanceToEdit: {
        default: function () {
          return {
            createDate: moment(new Date()).format('YYYY-MM-DD'),
            provider: null,
            total: '',
            concept: '',
            paid: '',
            balance: '',
            status: 'PENDIENTE',
          }
        },
        type: Object,
      },
    },
    data: data => ({
      loadingPrintAdvance: false,
      loadingSavePrint: false,
      validAdvanceMovement: true,
      dialogPaid: false,
      loadingSaveMovement: false,
      loadingSave: false,
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      requeridos: [
        value => !!value || 'Requerido',
      ],
      menuFrom: false,
      menuPaid: false,
      validAdvance: true,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      advance: {
        createDate: moment(new Date()).format('YYYY-MM-DD'),
        provider: null,
        total: '',
        concept: '',
        paid: '',
        balance: '',
        status: 'PENDIENTE',
      },
      movement: {
        paidDate: moment(new Date()).format('YYYY-MM-DD'),
        paid: '',
        concept: '',
        provider: null,
      },
      search: '',
      headers: [
        { text: 'Fecha de Pago', value: 'paidDate', align: 'left', filterable: true },
        { text: 'Monto Pagado', value: 'paid', align: 'left', filterable: true },
        { text: 'Concepto', value: 'concept', align: 'left', filterable: true },
        { text: 'ACCIONES', align: 'center', value: 'action', sortable: false },
      ],
      cargando: false,
      firstLoad: false,
      ordenarPor: '',
      banSendAdvance: '0',
      banSendAdvanceMovement: '0',
    }),
    computed: {
      arrayProveedores () {
        return this.$store.state.provider.lstProviders
      },
      movementsLoad () {
        return this.$store.state.advance.advancesMovementsLst
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
      providersLst () {
        return this.$store.state.provider.lstProviders
      },
      providersLstExterno () {
        return this.$store.state.provider.lstProvidersExternos
      },
    },
    watch: {
      advanceToEdit () {
        this.advance = this.advanceToEdit
        this.$store.dispatch('advance/loadAdvancesMovements', this.advance.id)
        this.movement = {
          paidDate: moment(new Date()).format('YYYY-MM-DD'),
          paid: '',
          concept: '',
          provider: null,
        }
      },
    },
    methods: {
      saveAdvance () {
        if (this.banSendAdvance === '0') {
          this.banSendAdvance = '1'
          setTimeout(() => {
            this.banSendAdvance = '0'
          }, 1000)
          if (this.$refs.formAdvance.validate()) {
            this.loadingSave = true
            if (!this.advance.movements) {
              this.advance.movements = ''
            }
            this.$store.dispatch('advance/saveAdvance', this.advance).then(res => {
              this.loadingSave = false
              if (res) {
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Anticipo agregado de manera correcta.',
                }
                this.$emit('closeModal')
              } else {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al momento de guardar el anticipo.',
                }
              }
            }, reject => {
              this.loadingSave = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de guardar el anticipo.',
              }
            })
          }
        }
      },
      saveAdvanceMovement () {
        if (this.banSendAdvanceMovement === '0') {
          this.banSendAdvanceMovement = '1'
          setTimeout(() => {
            this.banSendAdvanceMovement = '0'
          }, 1000)
          if (this.$refs.formAdvanceMovement.validate()) {
            this.loadingSaveMovement = true
            this.movement.provider = this.advance.provider
            this.$store.dispatch('advance/addNewAdvanceMovement', [this.advance, this.movement]).then(res => {
              this.loadingSaveMovement = false
              this.dialogPaid = false
              if (res) {
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Pago agregado de manera correcta.',
                }
                this.$store.dispatch('advance/loadAdvancesMovements', this.advance.id)
                if (this.advance.id) {
                  this.$store.dispatch('advance/loadEndAdvance', this.advance.id).then(res => {
                    if (res) {
                      this.advance = res
                    }
                  })
                }
              } else {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al momento de guardar el pago.',
                }
              }
            }, reject => {
              this.loadingSaveMovement = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de guardar el pago.',
              }
            })
          }
        }
      },
      printReceipt () {
        this.loadingSavePrint = true
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs === undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = JSON.stringify(recibojson)
        docDefinition = docDefinition.toString().replace('val-advance-total', this.advance.total)
        docDefinition = docDefinition.toString().replace('val-advance-total-dinners', this.advance.total)
        docDefinition = docDefinition.toString().replace('val-advance-company', this.profileLoaded.razonSocial)
        docDefinition = docDefinition.toString().replace('val-advance-concept', this.advance.concept)
        docDefinition = JSON.parse(docDefinition)
        pdfMake.createPdf(docDefinition).download('Recibo.pdf')
        this.loadingSavePrint = false
      },
      printReceiptSub (item) {
        this.loadingPrintAdvance = true
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs === undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = JSON.stringify(recibojson)
        docDefinition = docDefinition.toString().replace('val-advance-total', item.paid)
        docDefinition = docDefinition.toString().replace('val-advance-total-dinners', item.paid)
        docDefinition = docDefinition.toString().replace('val-advance-company', this.profileLoaded.razonSocial)
        docDefinition = docDefinition.toString().replace('val-advance-concept', item.concept)
        docDefinition = JSON.parse(docDefinition)
        pdfMake.createPdf(docDefinition).download('Recibo-' + item.paidDate + '.pdf')
        this.loadingPrintAdvance = false
      },
      addMovement () {
        this.dialogPaid = true
      },
    },
    mounted () {
      this.advance = this.advanceToEdit
      if (this.advance.id) {
        this.$store.dispatch('advance/loadAdvancesMovements', this.advance.id)
      } else {
        this.movementsLoad = []
      }
      this.movement = {
        paidDate: moment(new Date()).format('YYYY-MM-DD'),
        paid: '',
        concept: '',
      }
    },
  }
</script>
